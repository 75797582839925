.about {
    border: 1px solid gray;
    text-align: center;
    padding: 10px 10px 10px 10px;
    margin-top: 50px;
    height: 100%;
}

.about h3 {
    font-weight: bold;
    margin-top: 60px;
}

ul {
    list-style-type: none;
}