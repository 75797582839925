.infoCard {
    padding: 15px 15px 10px 10px;
    margin: 25px;
    width: 100%;
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.quote {
    margin-left: 5px ;
}

.img-fluid {
    height: 400px;
}

.box {
    margin-top: 15px;
    padding-top: 15px;
    width: 100%;
}

.sdlc-img {
    width: 50%;
    height: 600px;
    border: none !important;
}

.sdlc-info {
    text-align: center;
    margin: 200px 50px 200px;
    
}

.fonts {
    font-size: 30px;
}

