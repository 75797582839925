div {
    text-align: center;
}
.you {
    height: 100%;
    font-size: 20px;
}

.you h3 {
    font-weight: bold;
}