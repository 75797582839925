

.App {
  text-align: center;
}

.AppContainer {
  background-color: lightslategrey;
  /* background-image: url(./images/backgrnd.jpg);; */
}

